@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

/* login CSS */
body, a, p, label, input, button, h1,h2,h3, h4, h5, h6{
    font-family: 'Ubuntu', sans-serif;
}
input, button, a{
    box-shadow: none !important;
    outline: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-text-fill-color: #6D6E72;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 500s ease-in-out 0s;
}
a.gx-site-logo img {
    max-height: 60px;
    margin-left: 15px;
}
.login-container {
    max-width: 980px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 3;
}
img.login-logo {
    min-width: 250px;
}
.login-form .ant-row {
    align-items: center;
}
.login-page{
    max-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    font-family: 'Ubuntu', sans-serif;
    background-image: url(../images/login-bg2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.logo-divlogin{
    transition: all 10s ease-in-out;
    animation-name: logomove;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
}
@keyframes logomove {
    0% {
      opacity: 0;
      transform: translateX(-200%);
    }
    100% {
      opacity:1;
      transform: translateX(0%);
    }
}
.gx-signin-form{
    transition: all 10s ease-in-out;
    animation-name: formmove;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
}
@keyframes formmove {
    0% {
      opacity: 0;
      transform: translateX(200%);
    }
    100% {
      opacity:1;
      transform: translateX(0%);
    }
} 
.box-login {
    position: absolute;
    bottom: -60px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.box-login img{
    max-height: 180px;
    object-fit: contain;
    margin-right: 50px;
}
.box-login .box-1{
    animation: animateleft 0.4s linear;
}
@keyframes animateleft {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0px);
    }
}
.box-login .box-2{
    animation: animateright 0.4s linear;
}
@keyframes animateright {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0px);
    }
}

.login-form h5.ant-typography {
    letter-spacing: 0px;
    color: #333333;
    font-size: 26px;
    line-height: 1.4;
    margin-bottom: 20px;
}
.login-form form input, .login-form form .ant-form-item-control-input-content span.ant-input-affix-wrapper {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid rgb(158 157 157 / 42%);
    background: transparent !important;
    color: #6D6E72;
    font-size: 16px;
    box-shadow: none !important;
    outline: none;
    font-family: 'Ubuntu', sans-serif;
    padding: 15px;
    padding-left: 50px;
}
.login-form form input::placeholder{
    color: #6D6E72;
}
.login-form form .ant-row {
    position: relative;
}
.login-form form .ant-row span.anticon {
    position: absolute;
    left: 10px;
    top: 12px;
    font-size: 27px;
    color: #333333;
}
.ant-form-item-control-input-content a {
    color: #2a57a5;
    font-size: 16px;
}
.ant-form-item-control-input-content a:focus{
    color:red;
}

.ant-form-item.link-right {
    text-align: right;
    margin-bottom: 40px !important;
}
.link-right .ant-form-item-control-input {
    min-height: auto !important;
}
.login-form form  .ant-row.ant-form-item {
    margin-bottom: 10px;
}
.login-form form .ant-form-item-control-input-content button.ant-btn {
    background: #2a57a5 !important;
    border: 1px solid #2a57a5 !important;
    width: 100%;
    min-height: 55px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 7px !important;
    transition: 0.2s linear;
    color: #fff;
}
.login-form form .ant-form-item-control-input-content button.ant-btn:focus,
.login-form form .ant-form-item-control-input-content button.ant-btn:hover{
    background:#fff !important;
    border: 1px solid #2a57a5 !important;
    color:#2a57a5 !important;
    box-shadow: none;
    outline: none;
}

.login-form form#basic {
    max-width: 370px;
    margin: 0 auto;
}
.error{
    color: red;
    font-weight: 500;
}
.mt-2{
    margin-top: 20px;
}
.create-perm-form .ant-row {
    margin: 0;
    text-align: left;
}.create-user .ant-row.ant-form-item {
    align-items: center;
}

.create-perm-form{
    /* max-width: 500px; */
    margin-bottom: 20px;
    margin-left: 0;
}
.create-perm-form .ant-row input {
    margin-bottom: 5px;
}
span.note {
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    margin-top: 5px;
    display: inline-block;
}
.ant-form-item.link-right.back a {
    text-decoration: underline;
}
.jutify-end{
    justify-content: flex-end;
}
.jutify-middle{
    justify-content: center;
}
.ant-popover.ant-popconfirm .ant-popover-arrow {
    left: 56%;
}
.ant-popover.ant-popconfirm .ant-popover-content .ant-popover-buttons button.ant-btn {
    min-width: 60px;
    margin: 0 5px;
}
tbody.ant-table-tbody td.ant-table-cell button,
tbody.ant-table-tbody td.ant-table-cell a {
    border: 0;
    margin: 0;
    background: transparent;
    box-shadow: none !important;
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
}
tbody.ant-table-tbody td.ant-table-cell button.ant-switch {
    background: #ddd;
}
tbody.ant-table-tbody td.ant-table-cell button.ant-switch.ant-switch-checked {
    background: #2a57a5;
}
thead.ant-table-thead tr th::before{
    display: none;
}
.site-listtable thead.ant-table-thead tr:first-child th{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
main.ant-layout-content > .gx-main-content-wrapper {
    /* max-width: 1300px; */
    max-width: 100%;
    margin-left: 0;
    width: 100%;
}
ul.ant-menu li.ant-menu-item-group .ant-menu-item-group-title {
    color: #fff;
    font-size: 14px;
}
.breadcrumb{
    margin-bottom: 15px;
}
.breadcrumb > span,
.breadcrumb > a {
    font-weight: 400;
    color: #2a57a5;
}
.breadcrumb > span:last-child,
.breadcrumb > a:last-child {
    font-weight: 600;
}
.gx-card.card-site {
    text-align: left;
}
.gx-card.card-site center {
    text-align: left;
}
.gx-card.card-site center button.ant-btn.ant-btn-primary {
    min-width: 100px;
}
form.ant-form.ant-form-horizontal.selection-form .ant-row.ant-form-item {
    margin: 0;
}
td.ant-table-cell a button.ant-btn {
    text-align: center;
    display: block;
    margin: 0;
    width: 100%;
    padding: 0;
    line-height: 2;
    height: auto;
}
td.ant-table-cell > div {
    width: 100%;
    display: block !important;
    text-align: center;
}
th.ant-table-cell.expexted-date {
    min-width: 160px;
}
td.ant-table-cell.expexted-date {
    background: #fff;
}
.profile-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.profile-buttons .btns {
    margin: 0 6px;
}
table#assigned tr td {
    padding: 5px 10px;
}
.breadcrumb li.breadcrumb-item {
    list-style: none;
    display: inline-block;
    padding-right: 8px;
}
.ant-table-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.ant-table-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: inset 0 0 5px grey; 
}
.ant-table-body::-webkit-scrollbar-thumb {
    background: #2a57a5;
    border-radius: 10px;
}
.ant-popover-content {
    max-width: 270px;
}
.ant-modal-content button.ant-modal-close span.ant-modal-close-x {
    background: #2a57a5;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    border-radius: 10%;
}
.ant-modal-body .modal-heading h3 {
    font-weight: 500;
    text-align: center;
    font-size: 20px;
}
.ant-modal-body {
    padding: 40px 20px;
}
.ant-modal-body a.modal-link {
    background: #2a57a5;
    color: #fff;
    text-align: center;
    padding: 8px 20px;
    display: inline-block;
    border-radius: 6px;
    font-weight: 500;
}
.modal-data{
    text-align: center;
}
.modal-heading {
    margin-bottom: 20px;
}
.breadcrumb.crumb {
    border-bottom: 1px solid #2a57a5;
    padding-bottom: 10px;
}
.breadcrumb.crumb a {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}
.breadcrumb.crumb a:last-child {
    color: #535353;
}
table.profile-table label.ant-checkbox-wrapper span.ant-checkbox {
    top: -3px;
}
.site-selection-div {
    max-width: 500px;
    margin: 0 auto;
}
.site-selection-div form.ant-form.selection-form {
    margin-bottom: 30px;
}
.storage-buttons button.ant-btn{
    margin-left: 10px;
    margin-right: 10px;
}
.storage-buttons span.anticon-file-excel {
    margin-left: 5px;
}
.storage-buttons {
    text-align: right;
}
.site-listbtn {
    text-align: right;
}
.site-listbtn a {
    margin: 5px 10px;
    display: inline-block;
}
.site-listbtn a button {
    margin: 0;
}
.start-date {
    margin: 0 auto;
    text-align: center;
}
svg.ant-menu-item-icon {
    font-size: 18px !important;
    color: #fff;
}
ul.ant-menu.ant-menu-root h4 {
    padding: 0 25px;
    text-align: left;
    font-weight: 500;
}
.ant-modal.filter-modal {
    transform-origin: 0 0 !important;
}
p.attach {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}
span.ant-upload-list-item-name {
    padding-left: 20px !important;
}
span.ant-upload-span .ant-upload-text-icon span.anticon {
    top: 3px;
}
ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.management-menu.ant-menu-inline-collapsed li.ant-menu-item h5 {
    line-height: 2;
    height: 25px;
    font-weight: 500;
    margin: 0 !important;
    text-align: center;
    padding: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 14px;
    letter-spacing: 2px;
}
/* .ant-modal.filter-modal {
    max-height: 90vh;
    overflow-y: auto;
} */
.ant-modal.filter-modal .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
}
thead.ant-table-thead th.ant-table-cell.ant-table-cell-scrollbar {
    border: 0 !important;
}
.create-perm-form .ant-row {
    margin: 0 !important;
}
.ant-modal.filter-modal button {
    margin-bottom: 0;
}
.site-listbtn .popover {
    margin: 5px 0px;
}
span.read-more-less--more,
span.read-more-less--less{
    color: #2a57a5;
}
.ant-row.storage_unit form.ant-form .ant-row label {
    width: 100%;
    text-align: left;
    display: block;
    white-space: break-spaces;
}
.ant-row.storage_unit form .ant-row .ant-form-item-label {
    width: 100%;
    flex: 0 0 40%;
    max-width: 40%;
}
.ant-row.storage_unit form .ant-row .ant-form-item-label label {
    height: auto;
    font-size: 13px;
    padding-left: 10px;
}
.ant-row.storage_unit form .ant-row .ant-form-item-label label::before{
    position: absolute;
    left: 0;
}
.ant-modal.location_modal {
    width: 100% !important;
    max-width: 550px;
}
.mainloader {
    background: rgb(0 0 0 / 40%);
    display: flex;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99999999;
}
.ant-modal.location_modal ul.ant-pagination li, .ant-modal.location_modal ul.ant-pagination 
li.ant-pagination-options{
    min-width: 26px;
    height: 26px;
    line-height: 26px;
}
.ant-modal.location_modal ul.ant-pagination li a {
    font-size: 12px;
}
.ant-modal.location_modal ul.ant-pagination li.ant-pagination-options span.ant-select-selection-item {
    line-height: 26px;
    font-size: 12px;
}
.ant-modal.location_modal ul.ant-pagination li.ant-pagination-options .ant-select-selector {
    height: 26px !important;
}
.edit-delete {
    max-width: 180px;
}
.bom-table .ant-table-cell.ant-table-column-has-sorters {
    min-width: 150px;
}
.ant-table-thead > tr .minwidth200{
    min-width: 200px;
}

/* barcode css */
.barcode {
    max-width: 100%;
    padding: 30px;
    background-color: #fff;
    border: 1px solid #2a57a5;
    margin: 0 auto;
}
.barcode-section {
    padding: 50px 0;
}
.barcode table td img {
    max-width: 400px;
    width: 100%;
}
.barcode table td{
    border: 0 !important;
}
.barcode table tr{
    text-align: center;
    border: 0 !important;
}
.barcode h3{
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #2a57a5;
    color: #2a57a5;
    margin-top: 0;
    font-family: 'NoirPro', sans-serif;
}
.barcode table {
    text-align: center;
    margin: 0 auto;
}
.barcode table tr td{
    padding: 20px 15px;
}
/* barcode css end here */

.receiveactionform {
    margin-top: 30px !important;
}
.receiveactionform .ant-row.ant-form-item {
    justify-content: center;
}
.barcode_print {
    min-width: 200px !important;
}
.received-modal .po-number-details,
.ant-modal.location_modal .ant-modal-content .ant-table-wrapper{
    max-height: 65vh;
    overflow-y: auto;
}
.ant-row.ant-form-item.fullwidth {
    width: 100%;
    justify-content: center;
}
center.textcenter {
    padding: 0 25px;
}
.or-class {
    text-align: center;
    margin-bottom: 30px;
}
.received__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.received__items .receiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
span.ant-input-group-wrapper.ant-input-search {
    width: 100% !important;
    margin: 0;
    margin-right: 10px;
}
center.detail_item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
}
svg.receive-icon{
    font-size: 19px;
    position: relative;
    top: 5px;
}
.search_details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.search_details .search-menu {
    margin-bottom: 10px;
}
.scan-card .ant-card-head-title {
    padding: 16px 20px;
}
.show-itemform {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 20px;
}
.show-itemform .ant-row{
    align-items: center;
}
.show-itemform .ant-form-item-control-input input:focus{
    color: #fff !important;
    transition: none;
    border: 3px solid #000;

}
.show-itemform .ant-form-item-control-input input:-webkit-autofill,
.show-itemform .ant-form-item-control-input input:-webkit-autofill:hover, 
.show-itemform .ant-form-item-control-input input:-webkit-autofill:focus, 
.show-itemform .ant-form-item-control-input input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: yellow !important;
}

/* barcode scanner show information btn css 20-01-22 */
.gch{
    text-align: center;
}
.scane_code {
    display:flex;
    flex-direction:column-reverse;
}
.scane_text{
    text-align:center;
}
.showitem-form .scane_code .ant-row{
    display:none !important; 
    opacity:0;
}
.ant-row.ant-form-item.space-bottom {
    margin-bottom: 0;
}
.center-btn {
    text-align: center;
}
.ant-popover-buttons button{
    cursor: pointer;
}
.show-itemform {
    padding: 0;
}
.scane_text h5 {
    font-weight: 600;
    font-size: 18px;
}
.ant-modal.scan_modal .ant-modal-body {
    padding-top: 40px;
}
.showitem-form .ant-form-item-control-input .ant-form-item-control-input-content {
    text-align: center;
}
.showitem-form .ant-form-item-control-input .ant-form-item-control-input-content button.ant-btn {
    margin-bottom: 0;
}
.showitem-form .ant-row.ant-form-item.fullwidth {
    margin: 0;
}
th.ant-table-cell.width-main {
    min-width: 120px;
}
.ant-modal.scan_modal .ant-modal-body .ant-row {
    align-items: center;
    justify-content: center;
}
.ant-modal.scan_modal .ant-modal-body .ant-form-item-control-input{
    min-height: auto;
}
.scanning-input .ant-form-item-control-input-content input {
    max-width: 350px;
    display: block;
}
.scanning-input label {
    padding: 8px 0 0;
}
.site-selection-div .ant-row.ant-form-item {
    align-items: center;
}
.choose-po {
    display: block;
    /* margin-bottom: 25px; */
    width: 100%;
}
.managingcom-add .ant-row{
    align-items: center;
}
.dearch-result-management .ant-card-head-title {
    padding: 16px 20px;
}
.edit-manage-card .ant-typography {
    margin-bottom: 20px;
}
.edit-manage-card .ant-row{
    align-items: center;
}
.edit-manage-card .ant-row label{
    font-weight: 600;
    text-align: left;
    display: block;
}
.edit-manage-card .ant-row button {
    margin-bottom: 0;
}
.choose-po .ant-col {
    width: 25%;
    max-width: 300px;
    margin: 0 !important;
}
.edit-manage-card .ant-row span.ant-select-selection-item {
    text-align: left;
}
.dearch-result-management .search_details {
    justify-content: flex-end;
    margin-bottom: 10px;
}
.deploy-buttons {
    margin-bottom: 10px;
}
.deployview-buttons{
    margin-bottom: 10px;
    margin-left: 10px;
}
img.locationimg {
    max-width: 30px;
}
.storage-buttons.mr-2 button.ant-btn {
    margin-right: 0;
}
.cancel-remove button.ant-modal-close{
    display: none;
}
.ant-message {
    left: 0 !important;
    top: 0 !important;
    transition: all 0.01s ease-in-out;
}
.ant-message .ant-message-notice {
    left: 0;
    top: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.01s ease-in-out;
}
.choose-po > .ant-row {
    align-items: flex-end;
}
.mb2{
    margin-bottom: 3px;
    display: block;
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark li.ant-menu-item.ant-menu-item-only-child {
    line-height: 1;
    height: auto;
}
/* .ant-message .ant-message-notice::after{
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 20%);
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
} */
/* .select-name .ant-select-show-arrow.ant-select-open .ant-select-selector::after{
    content: "x";
    width: 12px;
    height: 35px;
    color: #000;
    font-size: 24px;
    visibility: visible;
    line-height: 1.3;
    text-align: center;
} */


@media screen and (max-width: 1399px) {
    .login-page::before{
        background-size: contain;
    }
}

@media screen and (max-width: 1199px) {
    .sitelist-row .ant-col button.ant-btn {
        width: 100%;
    }
}
@media screen and (max-width: 1099px) {
    .box-login img {
        max-height: 100px;
        margin-right: 0;
    }
    .box-login {
        bottom: 0;
    }
    .login-container {
        padding: 0 15px;
        max-width: 95%;
    }
}

@media screen and (max-width: 767px) {
    .choose-po .ant-col {
        width: 100%;
    }
    .received__items {
        flex-wrap: wrap;
    }
    /* .choose-po .ant-col {
        margin-top: 10px !important;
    } */
    .site-listbtn {
        text-align: left;
    }
    .storage-buttons button.ant-btn{
        margin-left: 0;
        margin-right: 15px;
    }
    .storage-buttons {
        text-align: left;
    }
    .site-selection-div form.ant-form.selection-form {
        margin-bottom: 10px;
    }
    img.login-logo {
        min-width: auto;
    }
    .logo-divlogin {
        text-align: center;
        margin-bottom: 30px;
    }
    .login-form h5.ant-typography {
        font-size: 22px;
        margin-bottom: 5px;
    }
    .ant-form-item.link-right {
        margin-bottom: 25px !important;
    }
    .box-login {
        overflow: hidden;
    }
    .box-login img {
        max-height: initial;
        width: 40%;
    }
    .login-form form .ant-row span.anticon {
        top: 16px;
        font-size: 20px;
    }
    .login-form form input {
        padding-left: 40px;
    }
    .login-form form .ant-form-item-control-input-content button.ant-btn {
        min-height: 45px;
    }
    form.ant-form.selection-form {
        padding: 0 0 15px;
    }
    .gx-header-notifications > li {
        font-size: 15px !important;
    }
}

@media screen and (max-width: 575px) {
    header.ant-layout-header a.gx-pointer img {
        max-width: 32px;
    }
}

@media screen and (min-width: 1700px) {
    main.ant-layout-content > .gx-main-content-wrapper {
        max-width: 95%;
    }
}

.report-generate .ant-row.ant-form-item {
    display: block;
}
.report-generate .ant-row.ant-form-item .ant-col {
    min-width: 100%;
}
